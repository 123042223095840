import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCFURL } from "utils/helpers";
import Lightbox from "yet-another-react-lightbox";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { modifyAnonymousGallaryRoute } from "utils/helpers/routes";
import { IMAGES_PER_PAGE } from "views/gallary/gallaryPage/components/FolderImages";
import HighResWarn from "views/components/modals/HighResWarn";
import useHighResAlert from "hooks/useHighResAlert";
import { isIosDevice } from "hooks/useDetectUserAgent";
import { pushState } from "utils/helpers/debounced-history";
import AnonymousGalleryToolbar from "./AnonymousGalleryToolbar";

const AnonymousGalleryModal = ({
  selectedID,
  closeImageView,
  handleDownload,
  toggleFavorite,
}) => {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const [initialImageId] = useState(selectedID || params.get("imageId"));
  const { images } = useSelector((state) => state.anonymous);
  const folderId = params.get("folderId");
  const page = Number(params.get("page"));
  const { fullScreenImages } = useSelector((state) => state.folderImages);

  const imageIndex = useMemo(() => {
    let idx = 0;
    if (folderId === "my-photos") {
      idx = images?.findIndex((image) => {
        return (
          image?.imageId === initialImageId || image?._id === initialImageId
        );
      });
    } else {
      idx = fullScreenImages?.findIndex((image) => {
        return image?._id === initialImageId;
      });
    }

    return idx >= 0 ? idx : 0;
  }, [initialImageId]);

  const [highResAlert, setHighResAlert] = useState(false);
  const [imgIndex, setImgIndex] = useState(imageIndex);
  const [imgSrcs, setImgSrcs] = useState([]);

  const { performCheck, setResult } = useHighResAlert(
    params.get("groupId"),
    "anonHighRes"
  );

  useEffect(() => {
    let highRes = false;

    let imgLink =
      folderId === "my-photos"
        ? images?.map((item) => {
            highRes = item?.highRes || highRes;

            return { src: getCFURL({ url: item?.url }) };
          })
        : fullScreenImages?.map((item) => {
            highRes = item?.highRes || highRes;

            return {
              src: getCFURL({ url: item?.url }),
            };
          });

    if (performCheck && isIosDevice()) {
      setHighResAlert(highRes);
      setResult(highRes);
    }
    setImgSrcs(imgLink);
  }, [folderId, fullScreenImages, images]);

  useEffect(() => {
    const newPage = Math.floor(imgIndex / IMAGES_PER_PAGE) + 1;
    let imgId;
    if (folderId === "my-photos") {
      imgId = images[imgIndex]?._id;
    } else if (folderId === "all-photos") {
      imgId = fullScreenImages[imgIndex]?._id;
    } else {
      imgId = fullScreenImages[imgIndex]?.imageId;
    }

    if (newPage !== page) {
      navigate(
        modifyAnonymousGallaryRoute({ imageId: imgId, pageNo: newPage }, params)
      );
    } else {
      pushState(
        null,
        "",
        modifyAnonymousGallaryRoute({ imageId: imgId }, params)
      );
    }
  }, [imgIndex, page]);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return () => document.removeEventListener("contextmenu", handleContextmenu);
  }, []);

  return (
    <>
      <div className="lightbox">
        {imgSrcs.length > 0 && (
          <Lightbox
            open={open}
            index={imgIndex}
            on={{
              view: ({ index }) => {
                setImgIndex(index);
              },
            }}
            close={closeImageView}
            carousel={{
              preload: 2,
            }}
            slides={imgSrcs}
            slideshow={{ autoplay: false, delay: 3000 }}
            plugins={[Zoom, Slideshow]}
            animation={{ zoom: 300 }}
            toolbar={{
              buttons: [
                <AnonymousGalleryToolbar
                  key={folderId}
                  image={
                    (folderId === "my-photos"
                      ? images[imgIndex]
                      : fullScreenImages[imgIndex]) || {}
                  }
                  imgIndex={imgIndex}
                  folderId={folderId}
                  myPhotosCount={images.length}
                  toggleFavorite={toggleFavorite}
                  handleDownload={handleDownload}
                />,
                isIosDevice() && (
                  <HighResWarn
                    key={Math.random()}
                    show={highResAlert}
                    onHide={() => setHighResAlert(false)}
                  />
                ),
              ],
            }}
            zoom={{
              maxZoomPixelRatio: 5,
              zoomInMultiplier: 2,
              doubleTapDelay: 300,
              doubleClickDelay: 300,
              doubleClickMaxStops: 2,
              keyboardMoveDistance: 50,
              wheelZoomDistanceFactor: 100,
              pinchZoomDistanceFactor: 100,
              scrollToZoom: true,
            }}
          />
        )}
      </div>
    </>
  );
};

export default AnonymousGalleryModal;
