import React, { useState, useEffect } from "react";
import {
  Outlet,
  Navigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import DefaultHeader from "../components/header/DefaultHeader";
import Sidebar from "../components/sidebar/Sidebar";
import ProtectedRoute from "./ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_DETAILS } from "redux-store/sagas/saga-actions";
import { getLocalStorageUser, lsProxy } from "utils/helpers/localstorage";
import { loadRZP } from "utils/helpers/loadRZP";
import useSubscription from "views/subscription/hooks/useSubscription";
import cx from "classnames";

const SettingsRoute = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const [goBackLoc, setGoBackLoc] = useState("/gallery");
  const { leaveParticipantSettings = {} } = useSelector(
    (state) => state?.settings
  );
  const location = useLocation();

  const { getMySubscriptionPlanAPI } = useSubscription();

  const isProfileSetting =
    location.pathname === "/profile-settings/list" ||
    location.pathname === "/profile-settings/profile" ||
    location.pathname === "/profile-settings/business" ||
    location.pathname === "/profile-settings/watermark" ||
    location.pathname === "/profile-settings/portfolio" ||
    location.pathname === "/profile-settings/wallet" ||
    location.pathname === "/profile-settings/flipbook" ||
    location.pathname === "/profile-settings/team-login" ||
    location.pathname === "/profile-settings/transactions";

  const token = params.get("token");
  if (token) lsProxy.setItem("token", "Bearer " + token);

  const { userType } = getLocalStorageUser();

  useEffect(() => {
    getMySubscriptionPlanAPI();

    loadRZP();
  }, []);

  useEffect(() => {
    if (token) {
      dispatch({
        type: GET_USER_DETAILS,
      });
    }
  }, [token]);

  if (!params.has("groupId") && !isProfileSetting) {
    return <Navigate to="/" />;
  }

  if (!userType && !token) return <Navigate to="/auth/login" />;

  if (userType === "USER") {
    if (location.pathname.includes("/profile-settings/business")) {
      return <Navigate to="/profile-settings/profile" />;
    }
    if (location.pathname.includes("/profile-settings/team-login")) {
      return <Navigate to="/profile-settings/profile" />;
    }
    if (location.pathname.includes("/profile-settings/flipbook")) {
      return <Navigate to="/profile-settings/profile" />;
    }
    if (location.pathname.includes("/profile-settings/watermark")) {
      return <Navigate to="/profile-settings/profile" />;
    }
    if (location.pathname.includes("/profile-settings/portfolio")) {
      return <Navigate to="/profile-settings/profile" />;
    }
    if (location.pathname.includes("/settings/client")) {
      return <Navigate to="/" />;
    }
    if (location.pathname.includes("/settings/sponsors")) {
      return <Navigate to="/" />;
    }
  }

  return (
    <ProtectedRoute>
      <div className="settingsRoute">
        {!token && (
          <DefaultHeader
            goBack={
              params.get("groupId")
                ? `${goBackLoc}?groupId=${params.get("groupId")}`
                : `${goBackLoc}`
            }
          />
        )}
        <div className="d-flex">
          <Sidebar
            isAdmin={leaveParticipantSettings.isAdmin}
            groupSettings={!isProfileSetting}
          />
          <div className={cx("rightSide", { "w-100": token })}>
            <Outlet context={[goBackLoc, setGoBackLoc]} />
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default SettingsRoute;
