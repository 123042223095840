import { createSlice } from "@reduxjs/toolkit";
import { updateImgFavStatus } from "./folderImages";

const initialState = {
  loading: false,
  anonymousUser: {},
  totalImages: 0,
  images: [],
  videos: [],
  group: {},
};

export const anonymous = createSlice({
  name: "anonymous",
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setAnonymousUserData(state, action) {
      return {
        ...state,
        anonymousUser: action?.payload,
      };
    },
    setAnonymousUserImages(state, action) {
      return {
        ...state,
        images: action?.payload?.results || [],
        videos: action?.payload?.group?.videos || [],
        anonymousUser: action?.payload?.user || state?.anonymousUser,
        totalImages:
          action?.payload?.totalImages || action?.payload?.results?.length || 0,
      };
    },
    setGroupData(state, action) {
      return {
        ...state,
        group: action.payload,
      };
    },
    toggleFavorite(state, action) {
      const { index, isFavorite, imageId, folderId } = action.payload;
      const image = state.images[index];
      const id = image.imageId || image._id;

      if (imageId === id) state.images[index].isFavorite = !isFavorite;
      else {
        const imageIndex = state.images.findIndex(
          (img) => (img.imageId || img._id) === imageId
        );
        state.images[imageIndex].isFavorite = !isFavorite;
      }

      if (folderId !== "favorites") {
        state.anonymousUser.group.favoritePicsCount += isFavorite ? -1 : 1;
      }
    },
    clearAnonymousUserData(state) {
      return {
        ...state,
        anonymousUser: {},
        totalImages: 0,
        images: [],
        videos: [],
        group: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateImgFavStatus, (state, action) => {
      const { folderId, isFavorite, anonymous } = action.payload;
      if (anonymous && folderId !== "favorites") {
        state.anonymousUser.group.favoritePicsCount += isFavorite ? -1 : 1;
      }
    });
  },
});

export const {
  setLoading,
  setAnonymousUserData,
  setAnonymousUserImages,
  setGroupData,
  toggleFavorite,
  clearAnonymousUserData,
} = anonymous.actions;
export default anonymous.reducer;
