import React, { useCallback, useLayoutEffect, useRef } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Masony from "react-masonry-component";
import { getCorrectURL, getDOURL } from "utils/helpers";
import "../../views/routes/styles/folderImages.css";
import Observable from "zen-observable";
import {
  allowShareDownload,
  getHeightAndWidhtOfImage,
  handleDownload,
} from "views/download/helpers";
import AnonymousGalleryModal from "./anonymousGalleryModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import EmptyAnonymousGallery from "./emptyAnonymousGallery";
import { modifyAnonymousGallaryRoute } from "utils/helpers/routes";
import { masonryOptions } from "views/gallary/gallaryPage/components/FolderImages";
import classNames from "classnames";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import ProductSalesIcons from "views/gallary/gallaryPage/components/ProductSalesIcons";
import ImageHoverOptions from "views/gallary/gallaryPage/components/ImageHoverOptions";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import { ssProxy } from "utils/helpers/localstorage";
import { TOGGLE_FAVORITE_ANONYMOUS } from "redux-store/sagas/saga-actions";

const SelectQualityModal = React.lazy(() =>
  import("views/gallary/gallaryPage/components/SelectQualityModal")
);
const CartAddWarn = React.lazy(() => import("./CartAddWarn"));

const LOAD_IMAGES_PER_TIME = 10;
const IMG_VIEW_INIT = {
  show: false,
  selectedID: null,
};

const AnonymousFolderImages = () => {
  const [imageView, setImageView] = useState(IMG_VIEW_INIT);
  const [updatedImages, setUpdatedImages] = useState([]);
  const [removeLoader, setRemoveLoader] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imagesToBeRendered, setImagesToBeRendered] = useState([]);
  const [cartWarn, setCartWarn] = useState(false);
  const [selectQualityModal, setSelectQualityModal] = useState(false);

  const imagesRefs = useRef({});

  const [params, setParams] = useSearchParams();
  const folderId = params.get("folderId");
  const groupId = params.get("groupId");
  const anonymoususerid = params.get("anonymousUserId");
  const page = +params.get("page");

  const {
    anonymousUser: {
      group,
      webSettings: { design, download, originalDownload } = {},
    },
    images,
    loading,
  } = useSelector((state) => state.anonymous);
  const { totalCount, allPics: _images } = useSelector(
    (state) => state.folderImages
  );
  const { colorMode, font, grid, padding, photoSize } = design || {};
  const { allowDownload } = download || {};
  const { isForProductSale, groupCode } = group || {};

  const fGroupCode = ssProxy.getItem("fGroupCode");
  const adminToken = ssProxy.getItem("adminToken");

  const navigate = useNavigate();
  useLayoutEffect(() => {
    window.onresize = (e) => {
      setWindowWidth(e.target.innerWidth);
    };
  }, []);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearFolderImages());
  //   };
  // }, [_folderId]);

  const { removedImagesRef } = useIntersectionObserver({
    updatedImages,
    folderId,
  });

  useEffect(() => {
    let _imagesToBeRendered = [...(imagesToBeRendered || [])];
    if (_imagesToBeRendered?.length === 0) return;
    const observable = new Observable(async (observer) => {
      if (_imagesToBeRendered?.length === 0) {
        observer.complete();
      }

      let newImages = _imagesToBeRendered.splice(0, LOAD_IMAGES_PER_TIME);
      let imagesCount = 0;

      for (const image of newImages) {
        const img = new Image();
        const URL = await getCorrectURL(image.url);

        img.src = URL;

        img.onload = function () {
          imagesCount += 1;
          newImages = newImages.map((_img) => {
            if (_img._id === image._id) {
              return {
                ..._img,
                webThumbWidth: img.width,
                webThumbHeight: img.height,
              };
            }
            return _img;
          });
          if (imagesCount === newImages.length) {
            observer.next({
              renderedImages: newImages,
              leftImages: _imagesToBeRendered,
            });
          }
        };

        img.onerror = () => {
          newImages = newImages.filter((_img) => _img._id !== image._id);
          if (imagesCount === newImages.length) {
            observer.next({
              renderedImages: newImages,
              leftImages: _imagesToBeRendered,
            });
          }
        };
      }
    });

    const subscription = observable.subscribe({
      next: ({ renderedImages, leftImages }) => {
        setUpdatedImages((prev) => [...(prev || []), ...renderedImages]);
        setImagesToBeRendered(leftImages);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [imagesToBeRendered, folderId]);

  useEffect(() => {
    const imagesWithNoWidthHeight = [];
    const _folderImages = folderId === "my-photos" ? images : _images[folderId];
    const imagesWithWidthHeight = _folderImages?.filter((image) => {
      if (image?.webThumbHeight && image?.webThumbWidth) {
        return true;
      } else {
        imagesWithNoWidthHeight.push(image);
        return false;
      }
    });
    setUpdatedImages(imagesWithWidthHeight);
    setImagesToBeRendered(imagesWithNoWidthHeight);
    return () => {
      setUpdatedImages([]);
    };
  }, [images, _images[folderId]]);

  const dispatch = useDispatch();

  const closeImageView = () => {
    params.delete("imageId");
    setParams(params);
    setImageView(IMG_VIEW_INIT);
  };

  const handleNavigate = (_id) => {
    navigate(modifyAnonymousGallaryRoute({ imageId: _id }, params));
    setImageView({
      show: true,
      selectedID: _id,
    });
  };

  const handleDownloadAnon = useCallback(
    ({ url, originalQuality }) => {
      allowShareDownload(
        false,
        allowDownload,
        () => {
          if (typeof originalQuality === "boolean") {
            ssProxy.setItem("anonDownloadOriginal", originalQuality);
          }

          const sessionDownloadPref = ssProxy.getItem("anonDownloadOriginal");

          if (originalDownload && !sessionDownloadPref) {
            return setSelectQualityModal({ url });
          }

          handleDownload(
            url,
            sessionDownloadPref === "true" || originalQuality
          );
        },
        "Download",
        isForProductSale,
        false // anon user can't purchase
      );
    },
    [allowDownload, originalDownload]
  );

  const toggleFavorite = useCallback(
    (imageId, index, isFavorite, fromModal = false) => {
      dispatch({
        type: TOGGLE_FAVORITE_ANONYMOUS,
        payload: {
          imageId,
          groupId,
          anonymoususerid,
          folderId,
          index,
          page,
          isFavorite,
          fromModal,
          anonymous: true,
        },
      });
    },
    [folderId, page]
  );

  const count = folderId === "my-photos" ? images.length : totalCount;
  if (count === 0) {
    return (
      <div className="d-flex justify-content-center vw-100 p-20">
        <EmptyAnonymousGallery />
      </div>
    );
  }

  return (
    <div key={folderId} className="no-overflow-x position-relative">
      <Masony
        options={masonryOptions}
        disableImagesLoaded={false}
        className={classNames(
          "gallaryPage",
          colorMode === 1 ? "light" : "dark",
          photoSize === 1 ? "sizeLarge" : "sizeSmall",
          grid === 1 ? "horizontal" : "vertical",
          padding === 1 ? "paddingLarge" : "paddingSmall",
          font === 1 ? "fontSerif" : "fontSans"
        )}
      >
        {updatedImages?.map((image, index) => {
          const { imageWidth, imageHeight } = getHeightAndWidhtOfImage({
            windowWidth,
            image,
          });

          const _id =
            folderId === "my-photos" && image?.imageId
              ? image.imageId
              : image?._id;

          return (
            <li className="photo-item" key={_id}>
              <div
                className="folder-imgs-image"
                style={{
                  width: `${imageWidth}px`,
                  height: `${imageHeight}px`,
                  position: "relative",
                }}
                id={"image_container-" + _id}
              >
                <React.Suspense fallback={<SuspenseLoader />}>
                  {isForProductSale && (
                    <ProductSalesIcons
                      src="listings"
                      uploadedByCurrentUser={false}
                      authCode={false}
                    />
                  )}
                </React.Suspense>

                <img
                  id={"image-" + _id}
                  style={{
                    height: "100%",
                    width: "100%",
                    transform: "scale(0)",
                    transition: "all .3s ease-out",
                  }}
                  onLoad={(e) => {
                    if (loading) {
                      //   setLoading(false);
                    }
                    setRemoveLoader((prev) => ({ ...prev, [_id]: true }));
                    imagesRefs.current[_id] = e.target?.src;
                    removedImagesRef.current[_id] = e.target?.src;
                    e.target.style.height = "100%";
                    e.target.style.width = "100%";
                    e.target.style.transform = "scale(1)";
                  }}
                  onError={(e) => {
                    const webThumbURL = getDOURL({
                      url: image?.url,
                      thumb: true,
                    });
                    imagesRefs.current[_id] = webThumbURL;
                    removedImagesRef.current[_id] = webThumbURL;
                    e.target.src = webThumbURL;
                  }}
                  onClick={() => handleNavigate(_id)}
                />
                {!removeLoader[_id] && (
                  <div
                    id={"loader-" + _id}
                    className="gallery-img-spinner-loader"
                  />
                )}

                <ImageHoverOptions
                  isForProductSale={isForProductSale}
                  image={image}
                  folderId={folderId}
                  imageId={_id}
                  index={index}
                  allowDownload={allowDownload}
                  addToCart={() => setCartWarn(true)}
                  handleDownload={handleDownloadAnon}
                  toggleFavorite={toggleFavorite}
                  isAnonymous={true}
                />
              </div>
            </li>
          );
        })}
      </Masony>

      {/* {imagesToBeRendered?.length > 0 ? (
        <ThreeDotAnimation
          customizeClass={"d-flex justify-content-center vw-100 mb-3 pt-5"}
        />
      ) : (
        <> </>
      )} */}

      {count > 0 && params.has("imageId") && (
        <AnonymousGalleryModal
          selectedID={imageView.selectedID}
          closeImageView={closeImageView}
          imagesRefs={imagesRefs}
          handleDownload={handleDownloadAnon}
          toggleFavorite={toggleFavorite}
        />
      )}

      <React.Suspense fallback={<SuspenseLoader />}>
        {cartWarn && (
          <CartAddWarn
            show={true}
            groupCode={groupCode}
            fGroupCode={fGroupCode}
            adminToken={adminToken}
            onCancel={() => setCartWarn(false)}
          />
        )}

        {selectQualityModal && (
          <SelectQualityModal
            show={selectQualityModal}
            isAnonymous={true}
            onHide={() => setSelectQualityModal(false)}
            downloadCallback={handleDownloadAnon}
          />
        )}
      </React.Suspense>
    </div>
  );
};

export default AnonymousFolderImages;
