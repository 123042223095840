import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootSaga from "./sagas";
import {
  auth,
  user,
  settings,
  groups,
  folders,
  folderImages,
  images,
  analytics,
  subscription,
  anonymous,
  globals,
  portfolio,
  cart,
  notifications,
  purchase,
} from "./slices";

const sagaMiddleware = createSagaMiddleware({ serializableCheck: false });

// All middlewares need to be passed in this array.
const middlewares = [sagaMiddleware];

const store = configureStore({
  reducer: {
    auth,
    user,
    settings,
    groups,
    folders,
    images,
    folderImages,
    analytics,
    subscription,
    anonymous,
    globals,
    portfolio,
    cart,
    notifications,
    purchase,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([...middlewares]),
});

sagaMiddleware.run(rootSaga);
export { store };
