import { toast } from "react-toastify";
import { isDirectorySelectionSupported } from "views/download/helpers";

export const handleDownloadRouter = (
  groupID,
  navigate,
  deviceType,
  setShowErrorModal,
  { isAdmin, allowDownload, bulkDownload = false, isAnonymous, anonymousUserId }
) => {
  if (!bulkDownload) {
    toast.error(
      "Bulk download is disabled in free plan. Ask group creator to upgrade their plan."
    );
    return;
  }

  if (!allowDownload && !isAdmin) {
    toast.error("Downloading is disabled. Contact group admin for access.");
    return;
  }

  if (
    (deviceType && deviceType.type !== "desktop") ||
    !isDirectorySelectionSupported()
  ) {
    if (deviceType.type === "mobile") setShowErrorModal(true);
    else
      toast.error(
        "Bulk Download is not supported on this browser. Please use Chrome, Edge or Opera on desktop for downloading full album."
      );
  } else {
    let link = isAnonymous
      ? `/anonymous/gallery/download?groupId=${groupID}&anonymousUserId=${anonymousUserId}`
      : `/gallery/download?groupId=${groupID}`;

    navigate(link);
  }
};

/**
 * @param {string} textContent
 * @param {string} fileName
 * @param {string} mime
 * @returns {void}
 */
export const downloadTxt = (textContent = "", fileName = "", mime = "") => {
  const file = new Blob([textContent], { type: mime });
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
};
