import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  walletHistory: [],
  purchaseHistory: [],
};

const purchaseSlice = createSlice({
  initialState,
  name: "purchase",
  reducers: {
    setPurchaseLoader(state, action) {
      state.loading = action.payload;
    },
    setWalletHistory(state, action) {
      state.walletHistory = action.payload;
    },
    setPurchaseHistory(state, action) {
      state.purchaseHistory = action.payload;
    },
  },
});

export const { setPurchaseLoader, setPurchaseHistory, setWalletHistory } =
  purchaseSlice.actions;
export default purchaseSlice.reducer;
