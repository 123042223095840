import React from "react";
import css from "./FolderImages.module.css";
import { useSelector } from "react-redux";
import { DownloadIcon } from "./LightboxToolbar";

/**
 * @typedef {Object} ImageHoverOptionsProps
 * @property {boolean} isForProductSale
 * @property {Object} image
 * @property {string} folderId
 * @property {string} imageId
 * @property {number} index
 * @property {function} handleDownload
 * @property {function} addToCart
 * @property {function} [removeFromCart]
 * @property {function} [toggleFavorite]
 * @property {boolean} [authCode]
 * @property {string} [currentUserId]
 * @property {boolean} [isAnonymous]
 * @param {ImageHoverOptionsProps} props
 */
const ImageHoverOptions = ({
  isForProductSale,
  image,
  folderId,
  imageId,
  index,
  handleDownload,
  addToCart,
  removeFromCart = () => {},
  toggleFavorite = () => {},
  authCode = false,
  currentUserId = "",
  isAnonymous = false,
}) => {
  const { mappedCartItems } = useSelector((state) => state.cart);

  return (
    <div className={css.hoverOptions}>
      {isForProductSale &&
      folderId !== "purchased" &&
      folderId !== "deleted" &&
      image?.uploadedBy !== currentUserId &&
      !authCode ? (
        <img
          className={css.cartIcon}
          {...(mappedCartItems[imageId]
            ? {
                src: "/assets/icons/remove-from-cart.png",
                onClick: () => removeFromCart(mappedCartItems[imageId]),
                title: "Remove from Cart",
              }
            : {
                src: "/assets/icons/add-to-cart.png",
                onClick: () => addToCart(imageId),
                title: "Add to Cart",
              })}
        />
      ) : null}

      {folderId !== "deleted" && (
        <img
          onClick={() =>
            isAnonymous
              ? toggleFavorite(imageId, index, image?.isFavorite)
              : toggleFavorite(
                  image?.url,
                  index,
                  image?.authCode,
                  false,
                  image?.isFavorite
                )
          }
          className={css.favIcon}
          alt="Favorite"
          {...(image?.isFavorite
            ? {
                src: "/assets/icons/favorite-filled.png",
                title: "Remove from Favorites",
              }
            : {
                src: "/assets/icons/favorite.png",
                title: "Add to Favorites",
              })}
        />
      )}
      {isAnonymous ? (
        <img
          onClick={() => {
            handleDownload({ url: image?.url });
          }}
          className={css.downloadIcon}
          src="/assets/icons/download.png"
          alt="Download"
          title="Download"
        />
      ) : (
        <DownloadIcon
          groupId={image?.groupId}
          image={image}
          iconClass={css.downloadIcon}
          progressClass={`${css.downloadIcon} ${css.downloadProgress}`}
          isForProductSale={isForProductSale}
          isImagePurchased={authCode}
        />
      )}
    </div>
  );
};

export default ImageHoverOptions;
