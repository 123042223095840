import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
  SEND_USER_CLICK_ANALYTICS,
  GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
} from "redux-store/sagas/saga-actions";
import Spinner from "../loader/Spinner";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import cx from "classnames";
import useDetectUserAgent from "hooks/useDetectUserAgent";
import SuspenseLoader from "../loader/SuspenseLoader";
import ANALYTICS from "constants/analyticsKeys";
const UserAgentErrorModal = React.lazy(() =>
  import("../modals/UserAgentModal")
);

const Sidebar = (props) => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const groupId = params.get("groupId");
  const { userType } = getLocalStorageUser();
  const {
    settingLoader,
    groupDetails: { isForProductSale },
  } = useSelector((state) => state.settings);
  const { loader } = useSelector((state) => state.folders);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [device, setDevice] = React.useState({ type: "desktop" });
  useDetectUserAgent(setDevice);

  const isPhotographer = userType === "PHOTOGRAPHER";
  const token = params.has("token");
  const viewBlocked = params.get("view") === "blocked";

  React.useEffect(() => {
    if (groupId) {
      dispatch({
        type: GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
        payload: { groupId, sendAll: true, sendBlockedUsers: viewBlocked },
      });
    }
  }, [viewBlocked]);

  return (
    <div
      className={cx("sidebar", {
        "d-none": token,
      })}
    >
      <Spinner loading={settingLoader || loader} />
      <div className="sidebarMenu">
        {props.groupSettings && (
          <ul>
            <li className="sidebarHeading">
              <h6>Group Settings</h6>
            </li>
            {props.isAdmin && (
              <>
                <SidebarCol
                  link={"/settings/general?groupId=" + groupId}
                  name="General Setting"
                />
                <SidebarCol
                  link={"/settings/participants?groupId=" + groupId}
                  name="Participants"
                  onClick={() =>
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.PARTICIPANT_SETTINGS] },
                    })
                  }
                  icon="/assets/images/icons/new-2.png"
                  iconClass="ms-3 hw-20px w-auto"
                />
                <SidebarCol
                  link={"/settings/privacy?groupId=" + groupId}
                  name="Privacy Setting"
                  onClick={() =>
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.PRIVACY_SETTINGS] },
                    })
                  }
                />
                <SidebarCol
                  link={"/settings/folders?groupId=" + groupId}
                  name="Folders"
                  onClick={() => {
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.FOLDER_SETTINGS] },
                    });
                  }}
                />
                {/* <SidebarCol
                  link={"/settings/delete-request?groupId=" + groupID}
                  name="Delete Request"
                /> */}
                <SidebarCol
                  link={"/settings/design?groupId=" + groupId}
                  name="Design"
                  icon="/assets/images/icons/premium.png"
                  onClick={() => {
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.DESIGN_SETTINGS] },
                    });
                  }}
                />
                <SidebarCol
                  link={"/settings/download?groupId=" + groupId}
                  name="Download Setting"
                  icon="/assets/images/icons/premium.png"
                  onClick={() => {
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.DOWNLOAD_SETTINGS] },
                    });
                  }}
                />
                <SidebarCol
                  link={"/settings/flipbook?groupId=" + groupId}
                  name="Digital Flipbook"
                  icon="/assets/images/icons/new-2.png"
                  iconClass="ms-3 hw-20px w-auto"
                  onClick={() =>
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.FLIPBOOK] },
                    })
                  }
                />
                {isPhotographer && (
                  <>
                    <SidebarCol
                      link={"/settings/sponsors?groupId=" + groupId}
                      name="Branding & Sponsors"
                      icon="/assets/images/icons/new-2.png"
                      iconClass="ms-3 hw-20px w-auto"
                      onClick={() =>
                        dispatch({
                          type: SEND_USER_CLICK_ANALYTICS,
                          payload: { buttons: [ANALYTICS.SPONSOR_SETTINGS] },
                        })
                      }
                    />
                    {isForProductSale ? (
                      <SidebarCol
                        link={`/settings/sell-photos?groupId=${groupId}`}
                        name="Sell Pictures"
                        icon="/assets/images/icons/new-2.png"
                        iconClass="ms-3 hw-20px w-auto"
                        onClick={() =>
                          dispatch({
                            type: SEND_USER_CLICK_ANALYTICS,
                            payload: {
                              buttons: [ANALYTICS.SELL_PICS_SETTINGS],
                            },
                          })
                        }
                      />
                    ) : null}
                    <SidebarCol
                      link={`/settings/client?groupId=${groupId}&isAdmin=true&page=1`}
                      name="Client Favorite"
                      icon="/assets/images/icons/premium.png"
                      onClick={() => {
                        dispatch({
                          type: SEND_USER_CLICK_ANALYTICS,
                          payload: { buttons: [ANALYTICS.CLIENT_FAVOURITES] },
                        });
                      }}
                    />
                  </>
                )}
              </>
            )}
            {!props.isAdmin && (
              <>
                <SidebarCol
                  link={"/settings/general?groupId=" + groupId}
                  name="General Setting"
                />
                <SidebarCol
                  link={"/settings/participants?groupId=" + groupId}
                  name="Participants"
                  onClick={() =>
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.PARTICIPANT_SETTINGS] },
                    })
                  }
                />
              </>
            )}
          </ul>
        )}

        {!props.groupSettings && (
          <ul className="nk-menu">
            <li className="sidebarHeading">
              <h6>
                {isPhotographer ? "Business Settings" : "Profile Settings"}
              </h6>
            </li>
            <SidebarCol link="/profile-settings/profile" name="Your Profile" />

            {isPhotographer && (
              <>
                <SidebarCol
                  link="/profile-settings/business"
                  name="Business Branding"
                  onClick={() =>
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.BRANDING_SETTINGS] },
                    })
                  }
                />
                <SidebarCol
                  link="/profile-settings/team-login"
                  name="Team Login"
                  icon="/assets/images/icons/new-2.png"
                  iconClass="ms-3 hw-20px w-auto"
                  onClick={() =>
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.TEAM_SETTINGS] },
                    })
                  }
                />
                <SidebarCol
                  link="/profile-settings/flipbook"
                  name="Flipbook Settings"
                  icon="/assets/images/icons/new-2.png"
                  iconClass="ms-3 hw-20px w-auto"
                  onClick={() =>
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.FLIPBOOK_SETTINGS] },
                    })
                  }
                />
                <SidebarCol
                  link="/profile-settings/watermark"
                  name="Watermark"
                  onClick={() =>
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.WATERMARK_SETTINGS] },
                    })
                  }
                />
                <SidebarCol
                  link="/profile-settings/portfolio"
                  name="Portfolio"
                  onClick={() =>
                    dispatch({
                      type: SEND_USER_CLICK_ANALYTICS,
                      payload: { buttons: [ANALYTICS.PORTFOLIO_SETTINGS] },
                    })
                  }
                />
              </>
            )}
            <SidebarCol
              link="/profile-settings/wallet"
              name="Kwikpic Wallet"
              icon="/assets/images/icons/new-2.png"
              iconClass="ms-3 hw-20px w-auto"
              onClick={() =>
                dispatch({
                  type: SEND_USER_CLICK_ANALYTICS,
                  payload: { buttons: [ANALYTICS.WALLET_SETTINGS] },
                })
              }
            />
            <SidebarCol
              link="/profile-settings/transactions"
              name="Transactions"
              iconClass="ms-3 hw-20px w-auto"
              icon="/assets/images/icons/new-2.png"
            />
          </ul>
        )}
      </div>
      <React.Suspense fallback={<SuspenseLoader />}>
        {showErrorModal ? (
          <UserAgentErrorModal
            show={showErrorModal}
            onHide={() => setShowErrorModal(false)}
            deviceType={device}
            variant={1}
          />
        ) : null}
      </React.Suspense>
    </div>
  );
};

export const SidebarCol = ({ link, onClick, name, icon, iconClass }) => {
  const isActive = window.location.pathname === link?.split("?")[0];

  // this is temporary, we'll remove this conditional class later
  return (
    <li
      className={cx(
        "sidebarItem",
        name === "Branding & Sponsors" ? "pe-2" : ""
      )}
    >
      <Link
        to={link}
        className={`d-flex align-items-center ${isActive ? "active" : ""}`}
        onClick={onClick}
      >
        <span>{name}</span>
        {icon && (
          <img
            src={icon}
            className={`small ms-1 ${iconClass ?? ""}`}
            width={"16px"}
            alt="icon"
          />
        )}
      </Link>
    </li>
  );
};

export default Sidebar;
