import { GET_PURCHASE_HISTORY, GET_WALLET_HISTORY } from "./saga-actions";

const { put, takeLatest } = require("redux-saga/effects");
const {
  setWalletHistory,
  setPurchaseLoader,
  setPurchaseHistory,
} = require("redux-store/slices/purchase");
const { AXIOS } = require("utils/setup/axios");

async function getWalletHistory() {
  return AXIOS.get("/api/payment/billing/wallet-history");
}

function* getWalletHistorySaga() {
  try {
    yield put(setPurchaseLoader(true));
    const res = yield getWalletHistory();
    yield put(setWalletHistory(res.data.data));
  } catch (error) {
    // console.error(error);
  } finally {
    yield put(setPurchaseLoader(false));
  }
}

async function getPurchaseHistory() {
  return AXIOS.get("/api/payment/billing/purchase-history");
}

function* getPurchaseHistorySaga() {
  try {
    yield put(setPurchaseLoader(true));
    const res = yield getPurchaseHistory();
    yield put(setPurchaseHistory(res.data.data));
  } catch (error) {
    // console.error(error);
  } finally {
    yield put(setPurchaseLoader(false));
  }
}

export function* purchaseSaga() {
  yield takeLatest(GET_WALLET_HISTORY, getWalletHistorySaga);
  yield takeLatest(GET_PURCHASE_HISTORY, getPurchaseHistorySaga);
}
